@import "variable";

a {
  text-decoration: none !important;
}

.custom-text-color {
  color: $brand;
}

//layout
@import "layout/footer";
@import "layout/header";
@import "layout/body";

//section_home
@import "home/banner";
@import "home/AchieveGoals";
@import "home/TutorCoursedWrap";
@import "home/FeaturedTutorsWrap";
@import "home/JoinedTutorsWrap";

//tutor_profile
@import "tutor_profile/about";

//guardian_profile
@import "guardian";

//tution scss
@import "chose_login";
@import "tution";
@import "tuitor";
@import "captcha";
@import "pop/_pop";
@import "loadmore";
@import "advertisement";
@import "previewimg";
@import "slider/slider";
@import "tutorLogin";
@import "responsive";
@import "custom_style";
