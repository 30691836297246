.joinUsWrap {
  .joinRow {
    margin: 0 -25px;
    .joinCol {
      padding: 0 25px;
    }
  }
}

.joinUsSingleItem {
  background: #f4f3fb url(../../frontend/img/tuitor/geoShape.png) no-repeat
    scroll center center / 100% 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 5.94px 0.06px rgba(10, 50, 110, 0.09);
  padding: 0 20px;

  .joinUsInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .joinUsImg {
      padding-left: 20px;
      img {
        max-width: 240px;
        width: 100%;
      }
    }
    .joinUsContent {
      padding: 48px 40px 30px 0;
      .joinUs {
        span {
          background-color: #0c76b2;
          color: #fff;
          display: inline-block;
          width: 60px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          text-align: center;
          border-radius: 5px;
        }
      }
      h3 {
        color: #0c76b2;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 10px;
      }
      .banglaText {
        min-height: 80px;
        margin-bottom: 20px;
        img {
          max-width: 130px;
          width: 100%;
        }
      }
      .btnSignUp {
        .signBtn {
          border-radius: 5px;
          background-color: rgb(12, 118, 178);
          box-shadow: none;
          display: inline-block;
          width: 110px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          color: #fff;
          transition: all 0.5s;
          &:hover {
            background-color: #f89a1c;
            box-shadow: 0px 30px 30px 0px rgba(10, 50, 110, 0.13);
          }
        }
      }
    }
  }
  &.tution {
    .joinUsInner {
      .joinUsImg {
        padding-left: 0px;
      }
      .joinUsContent {
        .joinUs {
          span {
            background-color: #f89a1c;
          }
        }
        h3 {
          color: #f89a1c;
        }
        .banglaText {
          img {
            max-width: 150px;
            width: 100%;
          }
        }
        .btnSignUp {
          .signBtn {
            background-color: rgb(248, 154, 28);
            &:hover {
              background-color: rgb(12, 118, 178);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .joinUsWrap {
    .joinRow {
      margin: 0 -15px;
      .joinCol {
        padding: 0 15px;
      }
    }
  }
  .joinUsSingleItem {
    padding: 0;
    .joinUsInner {
      .joinUsContent {
        padding: 48px 20px 30px 30px;
        h3 {
          font-size: 24px;
        }
        .banglaText {
          min-height: 55px;
          img {
            max-width: 90px;
          }
        }
      }
    }
    &.tution {
      .joinUsInner {
        .joinUsContent {
          .banglaText {
            img {
              max-width: 100px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .joinUsWrap {
    padding: 70px 0 40px;
  }
  .joinUsSingleItem {
    margin-bottom: 30px;
    .joinUsInner {
      .joinUsContent {
        padding-right: 100px;
      }
    }
  }
}
@media (max-width: 480px) {
  .joinUsWrap {
    padding: 70px 0;
  }
  .joinUsSingleItem {
    margin-bottom: 30px;
    padding-top: 30px;
    &.tution {
      padding-top: 0px;
      .joinUsInner {
        .joinUsContent {
          padding-top: 20px;
        }
      }
    }
    .joinUsInner {
      display: block;
      .joinUsImg {
        img {
          max-width: 100%;
        }
      }
      .joinUsContent {
        padding-right: 30px;
        padding-left: 30px;
        .joinUs {
          margin-bottom: 10px;
        }
      }
    }
  }
}
