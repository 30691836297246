/* =====================================          Request Tutor              =============================== */

@import "commom_mixin";

.tuition-request {
  margin: 2rem 0;

  .top-header {
    background: $brand;
    color: white;
    text-align: center;
    padding: 10px 0;
  }

  .tuition-request-form {
    margin-bottom: 2rem;
    .card {
      .card-header {
        @extend .top-header;
      }
      .card-body {
        .submit-form {
          .form-group {
            margin: 2px 0;
            label {
              margin: 10px 0;
            }
            input,
            select {
              //border: 0.2px solid #00113e;
              border-radius: 5px;
            }
            .btn-submit-request {
              background-color: $brand;
              font-size: 14px;
              font-weight: 500;
              color: white;
              text-decoration: none;
              font-family: "Montserrat", sans-serif;
              line-height: 21px;
              margin-top: 14px;
              &:hover {
                background-color: invert($brand);
                color: white;
              }
              &:focus {
                box-shadow: inherit;
              }
              i {
                padding: 8px 5px;
              }
            }
          }
        }
      }
    }
  }

  .tuition-request-help-info {
    .card {
      .card-header {
        @extend .top-header;
      }
    }
  }

  .otp-section {
    display: flex;
    justify-content: center;
    .form-group {
      margin: 2px 0;
      .row {
        display: flex;
        justify-content: center;
      }
      label {
        margin: 10px 0;
      }

      input {
        //border: 0.2px solid #00113e;
        border-radius: 5px;
      }
      button {
        margin-top: 5px;
      }
    }
  }
}

/* =====================================          End Tutor Request              =============================== */

/* =====================================          tuition list              =============================== */

.tuition-page-content {
  margin-top: 3rem;
}

.tuition-filter {
  margin-bottom: 30px;

  .bg-header {
    background: $brand;
    color: white;
    padding: 12px;
  }

  .form-row {
    .form-group {
      margin-bottom: 1rem;
    }
  }
}

.allGuardianRequest {
  margin-bottom: 30px;

  .card {
    border-radius: 10px;
    .card-header {
      padding: 12px;
    }

    .card-body {
      .custom-view-button {
        .btn-view {
          background-color: $brand;
          font-size: 14px;
          font-weight: 500;
          color: white;
          text-decoration: none;
          font-family: "Montserrat", sans-serif;
          margin-top: 2%;
          line-height: 21px;
          &:hover {
            background-color: invert($brand);
            color: white;
          }
          &:focus {
            box-shadow: inherit;
          }
        }
      }
    }
  }
}

.tuitionRequestDetails {
  // margin-top: 3rem;
  .card {
    margin: 3rem 0;
  }
  .card-header {
    @extend .top-header;
  }
  .card-body {
    .custom-view-button {
      .btn-view {
        background-color: $brand;
        font-size: 14px;
        font-weight: 500;
        color: white;
        text-decoration: none;
        font-family: "Montserrat", sans-serif;
        line-height: 21px;
        &:hover {
          background-color: invert($brand);
          color: white;
        }
        &:focus {
          box-shadow: inherit;
        }
      }
    }
  }
}

/* =====================================          End Tuition List              =============================== */

/*=====================================           Loader           =============================================*/
.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: all 0.5s ease-in-out;
  background-color: $brand;
  color: #fff;
  font-size: 40px;
  .loaderInner {
    i {
      animation: rot 2s linear infinite;
    }
  }
}

@keyframes rot {
  0% {
    transform: rotate(-360deg);
  }
}
/*=====================================           Loader           =============================================*/

/* =====================================          guardianLoginWrap              =============================== */
.guardianLoginWrap {
  label {
    margin: 0 10px 0 0;
  }
  .otp-button {
    background-color: $brand;
    font-weight: 500;
    color: white;
    text-decoration: none;
    &:hover {
      background-color: $brand-yellow;
      color: white;
    }
    &:focus {
      box-shadow: inherit;
    }
    i {
      padding: 2px;
    }
  }
}

.formWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  width: 100%;

  .formInner {
    width: 100%;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
    padding: 30px;
    border-radius: 10px;

    .formCenter {
      h3 {
        margin-bottom: 25px;
      }
      p {
        margin: 0;
      }
      .form-group {
        margin-bottom: 20px;
        label {
          margin-bottom: 5px;
        }
        .form-control {
        }
      }
    }
  }
}

.loginInput {
  position: relative;

  i {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.mobile-login {
  background-color: #212529;
  display: inline-flex;
  align-items: center;
  color: #fff;
  padding: 10px 33px !important;
  border-radius: 2px;
  border-color: #212529;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  width: 100%;
}

.google-login-btn {
  margin: 10px 0;
  width: 100%;
}

.kep-login-facebook {
  width: 100%;
}

/* =====================================          guardianLoginWrap              =============================== */

/* =====================================          tutorLoginWrap              =============================== */
.tutorLoginWrap {
  .form-group {
    margin-bottom: 20px;
    label {
      margin-bottom: 5px;
    }
  }
  a {
    text-decoration: none;
    color: #0a53be;
  }
}
/* =====================================          tutorLoginWrap              =============================== */

/*======================================          tutionInfoEdit              =============================== */
.tuition-info-edit {
  .card {
    .card-header {
      background: #0c76b2;
      color: white;
      text-align: center;
      padding: 10px 0;
    }
  }
}
.form-group.row {
  margin-bottom: 20px;
}
/*======================================          edit tutionInfoEdit              =============================== */

.custom-badge-danger {
  background: red;
  color: white;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
}
