@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
.container {
    max-width: 1140px !important;
    width: 100% !important;
}

html {
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -ms-background-clip: text;
    -moz-user-select: none;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 0.2s ease;
}

body {
    margin: 0 auto;
    padding: 0 0;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-overflow-scrolling: touch;
    transition: all 0.2s ease;
}

.bgGray {
    background-color: #f8f9fb;
}

// body 1 style
// body 2 style

/*--Gorila font cdn--*/

@import url('http://fonts.cdnfonts.com/css/gordita');

// body.modal-open
body.modal-open {
    // padding-right: 0 !important;
    // overflow: auto !important;
}

.helpInfo {
    margin-bottom: 30px;
}

script + iframe {
    display: none;
}