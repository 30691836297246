// responsive
@media (max-width: 991px) {
  .AllMainHeaderWrap .mainHeaderHome {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .LearnSearchTutorWrap
    #carouselExampleSlidesOnly1
    .VerifiedWrap1
    .VerifiedInnerText {
    margin-top: 90px;
  }
  .LearnSearchTutorWrap
    #carouselExampleSlidesOnly1
    .VerifiedWrap1
    .VerifiedInnerText
    .BetterText {
    font-size: 26px;
  }
  .LearnSearchTutorWrap
    #carouselExampleSlidesOnly1
    .VerifiedWrap1
    .VerifiedInnerText
    .LearnText {
    font-size: 42px;
    margin-bottom: 0;
  }
  .FeaturedTutorsSliderWrap .arrow.arrow-left {
    left: 5px;
  }
  .FeaturedTutorsSliderWrap .arrow.arrow-right {
    right: 5px;
  }
  .FeaturedTutorsSliderWrap {
    margin-bottom: 25px;
    overflow: hidden;
  }
}

//

@media (max-width: 767px) {
  .AllMainHeaderWrap .mainHeaderHome {
    margin: 0;
  }
  .LearnSearchTutorWrap
    #carouselExampleSlidesOnly1
    .VerifiedWrap1
    .VerifiedInnerText {
    margin-top: 40px;
  }
  .LearnSearchTutorWrap
    #carouselExampleSlidesOnly1
    .VerifiedWrap1
    .VerifiedInnerText
    .BetterText {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .LearnSearchTutorWrap
    #carouselExampleSlidesOnly1
    .VerifiedWrap1
    .VerifiedInnerText
    .LearnText {
    font-size: 30px;
  }
  .FeaturedTutorsWrap .FeaturedCatagory .TutorItem .TutorInner {
    padding: 0 5px;
  }
  .FeaturedTutorsSliderWrap .arrow {
    display: none;
  }

  .description {
    margin: 0;
    font-size: 14px;
  }
  .SocialProfileWrap .ServiceDigitWrap .ServiceInnerWrap .ServiceText {
    font-size: 12px;
  }
  .AboutWrap .Tuition-InfoWrap .ExperienceProfileWrap .SocialContactWrap {
    margin-top: 20px;
  }
  .AboutWrap .Tuition-InfoWrap .ExperienceProfileWrap .ProfileTextWrap1 {
    margin-left: 15px;
  }
  .AboutWrap
    .Tuition-InfoWrap
    .EducationWrap
    .AcademyContactWrap
    .ProfileTextInner {
    margin-left: 15px;
  }
  .ContactTutors {
    margin-bottom: 0;
  }
  .SocialProfileWrap .ProfileWrapInner .ProfileTextwrap .Graduation {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .SocialProfileWrap .ProfileWrapInner .ProfileTextwrap .Location {
    display: flex;
    align-items: center;
  }
  .AboutWrap .Tuition-InfoWrap .Designation {
    line-height: 20px;
  }
  .AllMainHeaderWrap
    .HeaderBottom
    .HeaderSocialInner
    .MailNumberWrap
    .NumberText {
    font-size: 10px;
  }
  .AboutWrap .Tuition-InfoWrap .TuitionInfoItem .tuitionSalaryInner {
    display: block;
  }
  .AboutWrap .Tuition-InfoWrap .TuitionInfoItem .tuitionSalaryInner .MonthText {
    text-align: left;
  }
  .AboutWrap .ProfileName {
    padding-right: 10px;
  }
  .DateYearText {
    padding-left: 0px;
  }
  .AboutWrap .SocialContactWrap .AddEditGlobBtn {
    top: 15px;
  }
  p {
    font-size: 12px;
  }
}

@media (max-width: 375px) {
  .SocialProfileWrap .ProfileWrapInner .ProfileImg:after {
    right: 60px;
  }
}
