@mixin common-btn($weight) {
  background-color: $brand;
  font-size: 14px;
  font-weight: $weight;
  color: white;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  line-height: 21px;
  &:hover {
    background-color: invert($brand);
    color: white;
  }
  &:focus {
    box-shadow: inherit;
  }
}