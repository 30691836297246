.advertisement {
  display: flex;
  align-items: center;
  background-clip: border-box;
  margin-bottom: 30px;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid lightgray;
  .buttonAddWrap {
    display: inline-block;
    background-color: $brand;
    color: white;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 0 solid transparent;
    padding: 5px 5px;
    margin: 15px 10px;
    font-size: 10px;
    border-radius: 0.25rem;
    transition: all 0.5s 0s ease;
    &:hover {
      background-color: $brand_yellow;
      color: white;
    }
  }
}