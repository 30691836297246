/*--JoinedTutorsWrap Section*/

.RecentlyJoinText {
    .ReCentlyText {
        margin-top: 40px;
        font-size: 28px;
        font-weight: 600;
        color: #252525;
    }
    .JoinText {
        position: relative;
    }
    .JoinText::after {
        content: "";
        position: absolute;
        height: 33px;
        width: 110px;
        left: 5px;
        top: 35px;
        transform: rotate(-4deg);
        border-radius: 50%;
        border: 3px solid #ffc837;
        border-color: #ffc837 transparent transparent transparent;
    }
}

.RecentJoinedTutorsWrap {
    .blog_content {
        .owl-carousel .owl-item img {
            display: inline-block;
            width: inherit;
            text-align: center;
        }
        .owl-theme .owl-nav {
            position: relative;
            margin-top: 25px;
        }
        .owl-prev {
            position: absolute;
            left: 0;
            top: -190px
        }
        .owl-next {
            position: absolute;
            right: 0;
            top: -190px
        }
        .owl-carousel .owl-nav button.owl-prev,
        .owl-carousel .owl-nav button.owl-next {
            background: white;
            color: #a7a7a7;
            width: 60px;
            height: 60px;
            align-items: center;
            text-align: center;
            box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.10);
            -webkit-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.10);
            border-radius: 50%;
        }
        .owl-carousel .owl-nav button.owl-prev {
            margin-left: -22px;
            margin-top: -30px;
        }
        .owl-carousel .owl-nav button.owl-next {
            margin-right: -22px;
            margin-top: 0px;
        }
        .owl-carousel .owl-nav button.owl-prev:focus-visible,
        .owl-carousel .owl-nav button.owl-next:focus-visible {
            border: inherit!important;
            border: 0;
            outline: none;
        }
        .owl-theme .owl-nav [class*='owl-'] {
            color: #FFF;
            font-size: 16px;
            padding: 4px 7px;
            background: red;
            display: inline-block;
            cursor: pointer;
            border-radius: 3px;
        }
        .owl-theme .owl-nav [class*="owl-"]:hover {
            background-color: #f48f1d;
            color: white;
        }
        .owl-carousel .owl-nav button.owl-next,
        .owl-carousel .owl-nav button.owl-prev,
        .owl-carousel button.owl-dot {
            cursor: pointer !important;
            padding: inherit!important;
            display: block;
            padding-bottom: 10px;
        }
        .owl-prev {
            position: relative;
        }
        .owl-prev>span:nth-child(1) {
            position: absolute;
            top: -4px;
            text-align: center;
            left: 22px;
            font-size: 40px;
        }
        .owl-next>span:nth-child(1) {
            position: absolute;
            top: -4px;
            text-align: center;
            right: 22px;
            font-size: 40px;
        }
        .owl-item .blog_item {
            margin: 7px;
        }
    }
}

@media screen and (max-width: 991px) {
    .owl-carousel.owl-loaded {
        overflow: hidden;
    }
}

@media screen and (max-width: 576px) {
    .RecentlyJoinText .ReCentlyText {
        font-size: 15px;
    }
    .RecentlyJoinText .JoinText::after {
        width: 62px;
        top: 20px;
        left: 0px;
        border: 2px solid #ffc837;
        border-color: #ffc837 transparent transparent transparent;
    }
    .RecentJoinedTutorsWrap .blog_content .owl-theme .owl-nav {
        margin-top: 85px;
    }
    .RecentJoinedTutorsWrap .blog_content .owl-carousel .owl-nav button.owl-prev {
        width: 40px;
        height: 40px;
        margin-left: -12px;
    }
    .RecentJoinedTutorsWrap .blog_content .owl-prev>span:nth-child(1) {
        left: 15px;
        display: block;
        top: -5px;
        font-size: 30px;
    }
    .RecentJoinedTutorsWrap .blog_content .owl-carousel .owl-nav button.owl-next {
        margin-right: -12px;
        width: 40px;
        height: 40px;
    }
    .RecentJoinedTutorsWrap .blog_content .owl-next>span:nth-child(1) {
        display: block;
        top: -5px;
        text-align: center;
        right: 15px;
        font-size: 30px;
    }
}