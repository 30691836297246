.choose-login{
  // margin-bottom: 10%;
  .chose-option {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    a {
      text-decoration: none;
      margin: 10px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 576px) {
  .choose-login{
    .chose-option {
      a {
        font-size: smaller;
      }
    }
  }
}