.tutor-img-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  .preview-img{
    width: 150px;
    height: 150px;
    position: relative;
    margin: auto;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #eee;
    background: #ddd;
    cursor: pointer;
  }
  .upload-btn {
    align-items: center;
  }
  transition: all 0.5s;
}