.modal-body {
  .form-group {
    margin-bottom: 30px;
    &.pb-2 {
      margin-bottom: 15px;
    }
  }
}

.AboutWrap {
  .Tuition-InfoWrap {
    .EducationWrap {
      .AcademyContactWrap {
        .ProfileTextInner {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.modalCOntrolS {
  .modal-title.h4 {
    font-size: 18px;
  }
  .col-md-4 {
    font-size: 14px;
  }
}

.table tr.table-active {
  border-top: 2px solid #d7d7d7;
}