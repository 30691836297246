.carousel-control-next-icon, .carousel-control-prev-icon {
  display: none !important;
}


@media screen and (min-width: 1400px) {
  display: none !important;
}

@media screen and (max-width: 991px) {
  display: none !important;
}

@media screen and (max-width: 576px) {
  display: none !important;
}