/*--FeaturedTutorsWrap Section--*/

.FeaturedTutorsWrap {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 30px;

  .TutorItemAllHover {
    text-decoration: none;
    color: inherit;
  }

  .ExplorerIteM {
    .propertyWrap {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 28px;
      font-weight: 600;
      color: #252525;
      position: relative;
      display: inline-block;
    }

    .FeaturedTexts {
      position: absolute;
      width: 100px;
      height: 40px;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }

    .FeaturedTexts::after {
      content: "";
      position: absolute;
      height: 33px;
      width: 100%;
      top: 35px;
      transform: rotate(-4deg);
      border-radius: 50%;
      border: 3px solid #ffc837;
      border-color: #ffc837 transparent transparent transparent;
      left: 0;
    }
  }

  .FeaturedCatagory {
    .TutorItem {
      display: block;
      padding: 0 0 40px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      margin-top: 110px;
      border-radius: 5px;
      width: 100%;

      .TutorIcon {
        width: 140px;
        height: 140px;
        transform: translateY(-50%);
        overflow: hidden;
        border-radius: 50%;
        margin: 0 auto -60px;
        border: 1px solid #ccc;

        img {
          overflow: hidden;
          display: inline-block;
          margin-top: 0;
          transition: all 0.5s;
        }
      }

      .verified-icon {
        position: absolute;
        top: 20px;
        right: 59px;
        max-width: 30px;
        height: auto;
      }
      .TutorInner {
        margin-bottom: 25px;

        .Tutor-Name {
          font-size: 22px;
          font-weight: 600;
          line-height: 30px;
          color: black;
          text-decoration: none;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          max-height: 32px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .MonthlySallaryText {
          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
          text-decoration: none;
          color: #2c2c2c;
        }

        .Location {
          font-size: 14px;
          font-weight: 400;
          text-decoration: none;
          color: #707070;
        }

        .checked {
          color: orange;
        }
      }

      .DetailsButton {
        padding: 8px 25px;
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        border-radius: 5px;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        border: none;
      }
    }

    .TutorItem:hover .TutorIcon img {
      -ms-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
}

@media only screen and (min-width: 577px) and (max-width: 766px) {
  .FeaturedTutorsWrap .FeaturedCatagory .TutorItem .TutorInner .Tutor-Name {
    font-size: 20px;
  }
  .FeaturedTutorsWrap .FeaturedCatagory .TutorItem .verified-icon {
    position: absolute;
    top: 0;
    right: 59px;
    max-width: 30px;
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .FeaturedTutorsWrap .FeaturedCatagory .TutorItem .verified-icon {
    position: absolute;
    top: 20px;
    right: 23px;
    max-width: 25px;
    height: auto;
  }
  .FeaturedTutorsWrap {
    text-align: center;
    margin-top: inherit;
  }
  .FeaturedTutorsWrap .ExplorerIteM .propertyWrap {
    margin-top: 30px;
    font-size: 15px;
  }
  .FeaturedTutorsWrap .FeaturedCatagory .TutorItem {
    padding: 20px 0;
    margin-top: 70px;
  }
  .FeaturedTutorsWrap .FeaturedCatagory .TutorItem .TutorIcon {
    margin: 0 auto -100px;
    width: auto;
  }
  .FeaturedTutorsWrap .FeaturedCatagory .TutorItem .TutorIcon img {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
  }
  .FeaturedTutorsWrap .FeaturedCatagory .TutorItem .TutorInner {
    margin-bottom: 15px;
    line-height: 12px;
  }
  .FeaturedTutorsWrap .FeaturedCatagory .TutorItem .TutorInner .Tutor-Name {
    font-size: 14px;
    line-height: 12px;
  }
  .FeaturedTutorsWrap
    .FeaturedCatagory
    .TutorItem
    .TutorInner
    .MonthlySallaryText,
  .FeaturedTutorsWrap .FeaturedCatagory .TutorItem .TutorInner .Location {
    font-size: 12px;
  }
  .FeaturedTutorsWrap .FeaturedCatagory .TutorItem .DetailsButton {
    padding: 6px 15px;
    font-size: 11px;
  }
  .FeaturedTutorsWrap .ExplorerIteM .FeaturedTexts {
    bottom: -5px;
    width: 80px;
    &:after {
      border-width: 2px;
    }
  }
}

// FeaturedTutorsSliderWrap

@import "https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.4/tiny-slider.css";
.FeaturedTutorsSliderWrap {
  position: relative;
  margin-bottom: 100px;

  .arrow {
    position: absolute;
    bottom: 130px;
    z-index: 2;

    button {
      width: 50px;
      height: 50px;
      border: none;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #fff;
      font-size: 30px;
      color: #a7a7a7;
      padding: 0;
      transition: all 0.5s;

      &:hover {
        background: #f48f1d;
        color: #fff;
      }
    }

    &.arrow-left {
      left: -15px;

      button {
        padding-right: 5px;
      }
    }

    &.arrow-right {
      right: -15px;

      button {
        padding-left: 5px;
      }
    }
  }

  .tns-controls {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    left: 0;
    bottom: 130px;

    button {
      position: relative;
      z-index: 2;
      opacity: 0;
      transform: translateX(-15px);
      padding: 0;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: all 0.5s;
      background: #fff;
      color: #a7a7a7;
      font-size: 16px;
      width: 50px;
      height: 50px;
      border: none;

      &:last-child {
        transform: translateX(15px);
      }
    }
  }
}

//   CUstom by RANA

// Reasponsive style

@media (max-width: 767px) {
  .FeaturedTutorsWrap .FeaturedCatagory .TutorItem .TutorIcon {
    width: 100px;
    height: 100px;
    margin: 0 auto -30px;
  }
}
