// Footer 1 style
/*--Fotter Section--*/
.footerAddress {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;
  text-align: left;
}
.ProfileFooterBox {
  background-color: #0c76b2;
  color: white;
  padding-top: 60px;
  margin-top: -60px;
  .FotterTextInner {
    margin-top: 10px;
    padding-bottom: 40px;
    .footerItem {
      .LogoPara {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        max-width: 296px;
        text-align: justify;
        margin-top: 20px;
      }
      .SocialFooterEnd {
        .SocialLogoWrap {
          display: flex;
          list-style: none;
          justify-content: start;
          padding: 0;
          margin: 0;
          li a {
            width: 40px;
            height: 40px;
            margin-right: 15px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            font-size: 20px;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            text-decoration: none;
            border-radius: 50%;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
          }
        }
      }
      .footerTitle {
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        margin-top: 20px;
      }
      .footerMenu {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-top: 20px;
        .LookingText {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 40px;
          text-align: left;
          max-width: 241px;
        }
        li a {
          font-size: 14px;
          line-height: 33px;
          font-weight: 400;
          color: white;
          text-decoration: none;
        }
        a:hover {
          color: #ff8800;
          text-decoration: underline;
        }
      }
      .RegisterButton {
        align-items: center;
        width: 138px;
        height: 46px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
      }
      .footerContact {
        .UpdatePara {
          padding-top: 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          margin: auto;
        }
        #email {
          color: white !important;
          background: #0c76b2;
          width: 231px;
          height: 37px;
          border-radius: 3px;
          border: 1px solid #e7e7e7;
          outline: none;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 14px;
          font-weight: 400;
        }
        #email::placeholder {
          color: #ddd !important;
          font-size: 14px;
        }
      }
      .SubscribeButton {
        align-items: center;
        width: 138px;
        height: 46px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        outline: none;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
      }
    }
  }
  .footerbottom {
    background-color: #005789;
    padding-top: 20px;
    .copyrightWrap {
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 576px) {
}

// Footer 1 style
// Footer 2 style
/*--Fotter Section--*/

.FooterBox {
  background-color: #0c76b2;
  color: white;
  padding-top: 60px;
  //margin-top: -70px;
  .FotterTextInner {
    margin-top: 10px;
    padding-bottom: 40px;
    .footerItem {
      .LogoPara {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        max-width: 296px;
        text-align: justify;
        margin-top: 20px;
      }
      .SocialFooterEnd {
        .SocialLogoWrap {
          display: flex;
          list-style: none;
          justify-content: start;
          padding: 0;
          margin: 0;
          li {
            .SocialButton {
              width: 40px;
              height: 40px;
              margin-right: 15px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              font-size: 20px;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              text-decoration: none;
              border-radius: 50%;
              -webkit-transition: all 0.5s;
              -o-transition: all 0.5s;
              transition: all 0.5s;
            }
          }
        }
      }
      .footerTitle {
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        margin-top: 20px;
      }
      .footerMenu {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-top: 20px;
        .LookingText {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 40px;
          text-align: left;
          //max-width: 241px;
        }
        li a {
          font-size: 14px;
          line-height: 33px;
          font-weight: 400;
          color: white;
          text-decoration: none;
        }
        a:hover {
          color: #ff8800;
          text-decoration: underline;
        }
      }
      .RegisterButton {
        align-items: center;
        width: 138px;
        height: 46px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
      }
      .footerContact {
        .UpdatePara {
          padding-top: 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          margin: auto;
        }
        #email {
          color: white !important;
          background: #0c76b2;
          width: 231px;
          height: 37px;
          border-radius: 3px;
          border: 1px solid #e7e7e7;
          outline: none;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 14px;
          font-weight: 400;
        }
        #email::placeholder {
          color: #ddd !important;
          font-size: 14px;
        }
      }
      .SubscribeButton {
        align-items: center;
        width: 138px;
        height: 46px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        outline: none;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
      }
    }
  }
  .footerbottom {
    background-color: #005789;
    padding-top: 20px;
    .copyrightWrap {
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}

.RegSubSolidButton {
  border: 1px solid #e7e7e7;
  background-color: white;
  color: #0c76b2;
}

.RegSubSolidButton:hover {
  color: #ff8800;
  border: 1px solid white;
  background-color: transparent;
}

@media screen and (max-width: 576px) {
  .FooterBox {
    padding-top: 10px;
  }
  .FooterBox .FotterTextInner {
    padding-bottom: 30px;
  }
  .FooterBox .FotterTextInner .footerItem .FooterLogo img {
    display: block;
    width: 120px;
  }
  .FooterBox .FotterTextInner .footerItem .LogoPara {
    font-size: 12px;
    text-align: left;
  }
  .FooterBox
    .FotterTextInner
    .footerItem
    .SocialFooterEnd
    .SocialLogoWrap
    li
    .SocialButton {
    width: 20px;
    height: 20px;
    font-size: 13px;
    margin-right: 10px;
  }
  .FooterBox .FotterTextInner .footerItem .footerTitle {
    font-size: 14px;
    margin-top: 40px;
  }
  .FooterBox .FotterTextInner .footerItem .footerMenu {
    margin-top: 10px;
  }
  .FooterBox .FotterTextInner .footerItem .footerMenu li .SocialButton,
  .FooterBox .FotterTextInner .footerItem .footerMenu .LookingText,
  .FooterBox .FotterTextInner .footerItem .footerContact .UpdatePara {
    font-size: 12px;
  }
  .FooterBox .FotterTextInner .footerItem .RegisterButton {
    margin-top: -20px;
    font-size: 12px;
    width: 100px;
    height: 30px;
  }
  .FooterBox .FotterTextInner .footerItem .footerContact #email {
    color: white !important;
    background: #0c76b2;
    width: 190px;
    height: 35px;
  }
  .FooterBox .FotterTextInner .footerItem .SubscribeButton {
    font-size: 12px;
    width: 100px;
    height: 30px;
  }
  .FooterBox .footerbottom .copyrightWrap p {
    font-size: 12px;
  }

  .ProfileFooterBox {
    padding-top: 10px;
    margin-top: -20px;
  }
  .ProfileFooterBox .FotterTextInner {
    padding-bottom: 30px;
  }
  .ProfileFooterBox .FotterTextInner .footerItem .FooterLogo img {
    display: block;
    width: 120px;
  }
  .ProfileFooterBox .FotterTextInner .footerItem .LogoPara {
    font-size: 12px;
    text-align: left;
  }
  .ProfileFooterBox
    .FotterTextInner
    .footerItem
    .SocialFooterEnd
    .SocialLogoWrap
    li
    .SocialButton {
    width: 20px;
    height: 20px;
    font-size: 13px;
    margin-right: 10px;
  }
  .ProfileFooterBox .FotterTextInner .footerItem .footerTitle {
    font-size: 14px;
    margin-top: 40px;
  }
  .FooterBox .FotterTextInner .footerItem .footerMenu {
    margin-top: 10px;
  }
  .ProfileFooterBox .FotterTextInner .footerItem .footerMenu li a,
  .ProfileFooterBox .FotterTextInner .footerItem .footerMenu .LookingText,
  .ProfileFooterBox .FotterTextInner .footerItem .footerContact .UpdatePara {
    font-size: 12px;
  }
  .ProfileFooterBox .FotterTextInner .footerItem .RegisterButton {
    margin-top: -20px;
    font-size: 12px;
    width: 100px;
    height: 30px;
  }
  .ProfileFooterBox .FotterTextInner .footerItem .footerContact #email {
    color: white !important;
    background: #0c76b2;
    width: 190px;
    height: 35px;
  }
  .ProfileFooterBox .FotterTextInner .footerItem .SubscribeButton {
    font-size: 12px;
    width: 100px;
    height: 30px;
  }
  .ProfileFooterBox .footerbottom .copyrightWrap p {
    font-size: 12px;
  }
}

// Footer 2 style

//   custom by Rana
.FooterBox .FotterTextInner .footerItem .LogoPara {
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  margin-bottom: 30px;
}

.footerTopSingleItem .footerTitle {
  color: #fff;
  position: relative;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 43px;
  &::before {
    position: absolute;
    content: "";
    bottom: -5px;
    left: 0;
    width: 50px;
    height: 2px;
    background: #ff7e3f;
  }
}
.footerTopSingleItem p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 30px;
  position: relative;
  padding-left: 30px;
}

.footerAddress p i {
  position: absolute;
  left: 0;
  top: 8px;
}

.footerMap {
  height: 250px;
  .contact_map {
    height: 100%;
    iframe {
      height: 100%;
      width: 100%;
    }
  }
}

// responsive style

@media (max-width: 991px) {
  
  .footerItem {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .footerItem {
    margin-bottom: 30px;
  }
  .FooterBox .FotterTextInner .footerItem .LogoPara {
    font-size: 12px;
    line-height: 22px;
  }
  .footerTopSingleItem .footerTitle {
    font-size: 18px;
  }
  .footerTopSingleItem .footerTitle::before {
    height: 1px;
    bottom: 0;
  }
  .footerTopSingleItem p {
    font-size: 12px;
    line-height: 22px;
    padding-left: 25px;
  }
  .guardian-dashboard .guardian-profile .guardian-profile-edit .card .card-body label {
    font-size: 12px;
  }
  .guardian-dashboard .guardian-profile .guardian-profile-edit .card .card-body .form-control {
    font-size: 12px;
  }
  .guardian-dashboard .guardian-profile .guardian-profile-edit .card .card-body .form-group {
   margin-bottom: 10px;
  }
}
