/**
*
*guardian-menu
*
 */
@import "commom_mixin";

.top-header {
  background: $brand;
  color: white;
  text-align: center;
  padding: 10px 0;
}

.guardian-dashboard {
  margin: 2rem 0;

  .guardian-profile{

    .guardian-profile-details {
      .card {
        margin-bottom: 2rem;
        .card-header{
          @extend .top-header
        }
        .card-footer {
          .profile-edit-btn {
            @include common-btn(500);
            float: right;
            i {
              padding: 1px 2px;
            }
          }
        }
      }
    }

    .guardian-profile-edit {
      .card {
        margin-bottom: 2rem;
        .card-header{
          @extend .top-header
        }
        .card-body{
          label {
            margin: 5px 0;
          }
          input,select {
            border-radius: 5px;
          }
        }
          .card-footer {
            .profile-update-btn {
              @include common-btn(500);
              float: right;
              i {
                padding: 1px 2px;
              }
            }
          }
      }
    }
  }





}

.guardian-menu {
  .card {
    margin-bottom: 2rem;
    .card-header {
      @extend .top-header
    }
    .card-body {
      .list-group {
        .list-group-item {
          a {
            text-decoration: none;
            color: black;
            &:hover {
              color: $brand_yellow
            }
          }
        }
      }
    }
  }

}

.guardian-tutor-request-list {

  .card {
    margin-bottom: 30px;
    .card-header {
      padding: 12px;
    }
    .card-footer {
        .btn-view-tutor-req{
          background-color: $brand;
          font-size: 14px;
          font-weight: 500;
          color: white;
          text-decoration: none;
          font-family: 'Montserrat', sans-serif;
          line-height: 21px;
          float: right;
          margin-left: 5px;
          padding: 4px 10px;
          &:hover {
            background-color: invert($brand);
            color: white;
          }
          &:focus {
            box-shadow: inherit;
          }
          i {
            padding: 1px 2px;
          }
        }

        .btn-success {
          font-size: 14px;
          font-weight: 500;
          color: white;
          text-decoration: none;
          font-family: 'Montserrat', sans-serif;
          line-height: 14px;
          float: right;
          &:hover {
            background-color: invert($brand);
            color: white;
          }
          &:focus {
            box-shadow: inherit;
          }
          i {
            padding: 1px 2px;
          }
        }

        .btn-danger{
        font-size: 14px;
        font-weight: 500;
        color: white;
        text-decoration: none;
        font-family: 'Montserrat', sans-serif;
        line-height: 14px;
        float: right;
        &:hover {
          background-color: invert($brand);
          color: white;
        }
        &:focus {
          box-shadow: inherit;
        }
        i {
          padding: 1px 2px;
        }
      }
    }
  }
}



.guardianDetailsTable {
  tr {
    td {
      padding: 5px 0;
        font-size: 12px;
      b {
        padding: 0;
        font-weight: 400;
      }
      &:nth-child(1){
        min-width: 100px;
      }
      &:nth-child(2){
        min-width: 30px;
        text-align: center;
      }
    }
  }
}