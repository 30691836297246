.load-more {
  width: 200px;
  color: #fff;
  display: block;
  text-align: center;
  margin: 20px auto;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: $brand;
  clear: both;
  transition: .3s;
}
.load-more:hover {
  color: blue;
  background-color: #fff;
  border: 1px solid blue;
  text-decoration: none;
}