.tutor-notification {
  margin-top: 2%;
}

.tutor-notification-table {
  .card {
    margin-bottom: 2%;

    .card-header {
      background: $brand;
      color: white;
      text-align: center;
      padding: 10px 0;
    }

  }
}

.tutor-menu {
  .card {
    margin-bottom: 2%;

    .card-header {
      background: $brand;
      color: white;
      text-align: center;
      padding: 10px 0;
    }

    .card-body {
      .notificationMenu {
        li {
          list-style-type: none;
          margin: 2px;
          padding-left: 0;

          .active-menu {
            color: #f9a23f;
          }

          i {
            margin-right: 5px;
          }

          a {
            &:hover {
              color: #f9a23f;
            }
          }

          //.active-menu {
          //  color: #f9a23f;
          //}
        }
      }
    }

  }
}

.guardian-menu {
  .card {
    margin-bottom: 2%;

    .card-header {
      background: $brand;
      color: white;
      text-align: center;
      padding: 10px 0;
    }

    .card-body {
      ul {
        li {
          list-style-type: none;
          margin-left: -26px;

          .active-menu {
            color: #f9a23f;
          }

          a {
            &:hover {
              color: #f9a23f;
            }
          }
        }
      }
    }
  }
}

.description {
  word-break: break-all;
}

.tutor-send-msg-form {
  margin-bottom: 5px;

  .card-header {
    background: $brand;
    color: white;
    text-align: center;
    padding: 10px 0;
  }

  .card-body {
    .form-group {
      margin: 2px 0;

      input {
        border-radius: 5px;
        margin-bottom: 20px;
      }

      .btn-submit-request {
        background-color: $brand;
        font-size: 14px;
        font-weight: 500;
        color: white;
        text-decoration: none;
        font-family: 'Montserrat', sans-serif;
        line-height: 21px;
        margin-top: 14px;

        &:hover {
          background-color: invert($brand);
          color: white;
        }

        &:focus {
          box-shadow: inherit;
        }

        i {
          padding: 8px 5px;
        }
      }
    }
  }
}


.h50vh {
  min-height: 50vh;
}
.tutor-menu .card .card-body .notificationMenu {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 5px 0;
    display: flex;
    align-items: center;

    i {
      margin-right: 10px;

    }

    a {
      color: rgba(0, 0, 0, .55);
      font-weight: 500;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}