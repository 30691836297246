 /*--AchieveGoals Section--*/
 
 .AchieveGoalsWrap {
     margin: 2em 0;
     .MainAchiever {
         background-color: #f7f7f7;
         padding: 20px;
         display: flex;
         position: relative;
         .AcgieveInnerText {
             padding: 20px;
             padding-left: 60px;
             .AchieveText {
                 font-size: 24px;
                 font-weight: 700;
                 line-height: 24px;
                 color: #252525;
             }
             .accountText {
                 font-size: 16px;
                 font-weight: 400;
                 line-height: 25px;
                 margin-bottom: 25px;
             }
             .ButtonArrowWrap {
                 display: flex;
                 justify-content: start;
                 margin-left: -65px;
                 .Goals-RightBarImg img {
                     margin-top: -30px;
                     margin-right: 15px;
                 }
                 .freeButton{
                     padding: 8px 25px;
                  
                     font-size: 14px;
                     font-weight: 500;
                     border: none;
               
                     text-decoration: none;
                     border-radius: 5px;
                     -webkit-transition: all 0.5s;
                     -o-transition: all 0.5s;
                     transition: all 0.5s;
                 }
          
             }
         }
         .Goals-StudyImg {
             position: absolute;
             top: 0;
             bottom: 0;
             display: inline-flex;
             overflow: hidden;
             img {
                 -webkit-transition: all 0.5s;
                 -o-transition: all 0.5s;
                 transition: all 0.5s;
             }
         }
         .Goals-StudyImg img:hover {
             -ms-transform: scale(1.2);
             -webkit-transform: scale(1.2);
             transform: scale(1.2);
         }
     }
 }
 
 @media screen and (max-width: 576px) {
     .AchieveGoalsWrap .MainAchiever {
         display: block;
     }
     .AchieveGoalsWrap .MainAchiever .AcgieveInnerText .AchieveText {
         font-size: 15px;
         margin-bottom: 20px;
     }
     .AchieveGoalsWrap .MainAchiever .AcgieveInnerText .accountText {
         font-size: 12px;
     }
     .AchieveGoalsWrap .MainAchiever .Goals-StudyImg {
         position: inherit;
         margin-top: 20px;
     }
     .AchieveGoalsWrap .MainAchiever .AcgieveInnerText .ButtonArrowWrap .freeButton {
         padding: 6px 12px;
         font-size: 11px;
     }
     
 }

 @media screen and (max-width: 991px) {
 .AchieveGoalsWrap .MainAchiever .Goals-StudyImg{
    position: inherit;
 }
}