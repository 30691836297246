
.modal-log {
   max-width: 330px;
   transform: translateY(120px)!important; 
}

.modal-head{
display:none;
}
.modal-mainbody{
  padding:inherit;  
}
.awesome-button-section .awesome-button {

  text-align: center;
}
.awesome-button-section .awesome-button .checked-icon {
  color: white;
  background-color: $brand;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  font-size: 35px;
  padding: 22px;
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
}
.awesome-button-section .awesome-button .awesome-heaading {
  color: black;
  margin-top: -40px;
  opacity: 0.7;
  font-size: 30px;
  font-weight: 500;
}
.awesome-button-section .awesome-button .booking-text {
  color: #272525;
  margin-top: 30px;
  margin-bottom: 40px;
  opacity: 0.7;
  font-size: 17px;
  font-weight: 500;
}
.awesome-button-section .awesome-button .ok-button {
  color: white;
  background-color: $brand;
  padding: 5px 32%;
  border: inherit;
  margin-bottom: 30px;
  border-radius: 5px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.awesome-button-section .awesome-button .ok-button:hover {
  background: $brand;
}