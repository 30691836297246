.captcha-wrapper {
  background-color: #f3f9ff;
  border: 2px solid #999;
  border-radius: 5px;
  overflow: hidden;
  .form-control {
    border: 1px solid #ccc !important;
    margin: 0 1% 1%;
    text-align: left;
    padding-left: 1em;
    width: 98%;
  }
}